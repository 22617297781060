import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Select, Tooltip, Avatar, Upload, message, Badge, Row, Col } from 'antd';
import { EditOutlined, DeleteOutlined, UserOutlined, UploadOutlined, SearchOutlined } from '@ant-design/icons';
import Request from '../../common/Request';
import { base_url } from '../../common';

const { Option } = Select;

const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [filterStatus, setFilterStatus] = useState(null);

  const showEditModal = (record) => {
    const user = {
      ...record,
      first_name: record.name.split(' ')[0],
      last_name: record.name.split(' ')[1],
    };
    setEditingUser(user);
    form.setFieldsValue(user);
    setIsEditModalVisible(true);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };

  const showDeleteModal = (record) => {
    setEditingUser(record);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteOk = async () => {
    try {
      await Request({ method: "delete", url: `superuser/update-teacherprofiles/${editingUser.uuid}/` });
      getUserList();
      message.success("User deleted permanently");
    } catch (err) {
      console.log(err);
    } finally {
      setIsDeleteModalVisible(false);
    }
  };

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      let formData = new FormData();
      formData.append('email', values.email);
      formData.append('first_name', values.first_name);
      formData.append('last_name', values.last_name);
      formData.append('is_active', values.status);
      if (profileImage) {
        formData.append('profile_picture', profileImage);
      }

      await Request({
        method: "put",
        url: `superuser/update-teacherprofiles/${editingUser.uuid}/`,
        data: formData
      });

      getUserList();
      setProfileImage(null);
      message.success("User updated successfully");
    } catch (err) {
      console.log(err);
      message.error("Failed to update user");
    } finally {
      setIsEditModalVisible(false);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsImageModalVisible(true);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    const filtered = data.filter(item => 
      item.name.toLowerCase().includes(value.toLowerCase()) || 
      item.email.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleStatusFilter = (value) => {
    setFilterStatus(value);
    const filtered = data.filter(item => item.status === value);
    setFilteredData(filtered);
  };

  const columns = [
    {
      title: 'Profile Image',
      key: 'profileImage',
      render: (_, record) => (
        record.profileImage ? (
          <Avatar
            src={record.profileImage}
            onClick={() => handleImageClick(base_url + record.profileImage)}
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <Avatar icon={<UserOutlined />} />
        )
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => capitalizeWords(text),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase())
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      filterIcon: () => <SearchOutlined />,
      onFilter: (value, record) => record.email.toLowerCase().includes(value.toLowerCase())
    },
    // {
    //   title: 'Subscription',
    //   dataIndex: 'subscription',
    //   key: 'subscription',
    // },
    {
      title: 'Available Tokens',
      dataIndex: 'openai_tokens',
      key: 'openai_tokens',
    },
    {
      title: 'Used Tokens',
      dataIndex: 'used_tokens',
      key: 'used_tokens',
    },
    {
      title: 'Status',
      key: 'status',
      filters: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, record) => (
        <Badge
          status={record.status ? "success" : "error"}
          text={record.status ? "Active" : "Inactive"}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Tooltip title="Edit">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => showEditModal(record)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              type="primary"
              shape="circle"
              danger
              icon={<DeleteOutlined />}
              onClick={() => showDeleteModal(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const getUserList = async () => {
    setLoader(true);
    try {
      const res = await Request({ method: "get", url: "superuser/users-list/" });
      const la_arr = res.map((item) => ({
        uuid: item.uuid,
        profileImage: item.profile_picture,
        subscription: "Free",
        ...item.user,
        status: item.user.is_active,
        name: `${item.user.first_name} ${item.user.last_name}`,
        openai_tokens:item?.openai_tokens??0 ,
        used_tokens:item?.used_tokens??0
      }));
      setData(la_arr);
      setFilteredData(la_arr);
    } catch (err) {
      console.log("err",err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <div>
      <Row justify="end" style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder="Search by name or email"
            value={searchText}
            onChange={handleSearch}
            suffix={<SearchOutlined />}
            style={{ width: 200 }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        loading={loader}
        rowKey="uuid"
        pagination={{
          pageSize: 7,
          current: currentPage,
          onChange: (page) => setCurrentPage(page),
        }}
      />

      {/* Edit Modal */}
      <Modal
        title="Edit User"
        visible={isEditModalVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="profileImage" label="Profile Image">
            <Upload
              listType="picture-card"
              showUploadList={false}
              beforeUpload={(file) => {
                const reader = new FileReader();
                reader.onload = e => {
                  setProfileImage(file);
                  setEditingUser({
                    ...editingUser,
                    profileImage: e.target.result,
                    isLocal: true,
                  });
                  form.setFieldsValue({ profileImage: e.target.result });
                };
                reader.readAsDataURL(file);
                return false; // Prevent upload
              }}
              onChange={(e) => console.log(e)}
            >
              {editingUser?.profileImage ? (
                <img
                  src={editingUser.isLocal ? editingUser?.profileImage : editingUser?.profileImage}
                  alt="avatar"
                  style={{ width: '100%' }}
                />
              ) : (
                <div>
                  <UploadOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: 'Please input the first name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: 'Please input the last name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please input the email!' }]}
          >
            <Input disabled />
          </Form.Item>
          {/* <Form.Item name="subscription" label="Subscription">
            <Select>
              <Option value="Free">Free</Option>
              <Option value="Premium">Premium</Option>
              <Option value="VIP">VIP</Option>
            </Select>
          </Form.Item> */}
          <Form.Item name="status" label="Status" initialValue={editingUser?.status}>
            <Select>
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {/* Delete Modal */}
      <Modal
        title="Confirm Deletion"
        visible={isDeleteModalVisible}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      >
        <p>Are you sure you want to permanently delete this user?</p>
      </Modal>

      {/* Image Modal */}
      <Modal
        title="Profile Image"
        visible={isImageModalVisible}
        footer={null}
        onCancel={() => setIsImageModalVisible(false)}
      >
        <img
          src={selectedImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>
    </div>
  );
};

export default Dashboard;
